import React from 'react';
import { Link, graphql } from 'gatsby';
// import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3'
import Layout from '../components/layout';
import PhotoGalleryComponent from '../components/photo-gallery-component';
import { X } from 'react-feather';

const GalleryContent = ({ queryData }) => {
	return (
		<div className="py-6 px-4">
			<PhotoGalleryComponent gallery={queryData.contentfulPhotoGallery} />
		</div>
	);
};

//page can be linked to directly or via modal using ModalRoutingContext
const PhotoGalleryModalRoutingContextTemplate = props => {
	const modal = true;
	return (
		<div>
			{modal ? (
				<div className="h-full overflow-y-scroll">
					<div className="gvd-main-bg px-4 py-3 sticky top-0 z-20 flex items-center justify-between">
						<div>
							<h2 className="p-0 m-0 text-lg md:text-xl ">
								Recently Completed Projects
							</h2>
						</div>
						<Link
							className="bg-white hover:gvd-main-bg-darker flex h-8 w-8 items-center justify-center p-1 rounded text-black"
							//   to={closeTo}
							state={{
								noScroll: true
							}}
						>
							<X size={28} />
							<span className="sr-only">Close Menu</span>
						</Link>
					</div>

					<GalleryContent queryData={props.data} />
				</div>
			) : (
				<Layout pageProps={props}>
					<GalleryContent queryData={props.data} />
				</Layout>
			)}
		</div>
	);
};

export default PhotoGalleryModalRoutingContextTemplate;

export const pageQuery = graphql`
	query photoGalleryQuery($slug: String!) {
		contentfulPhotoGallery(slug: { eq: $slug }) {
			id
			slug
			title
			photos {
				id
				description
				gatsbyImageData(width: 150, quality: 70)
				fullSize: gatsbyImageData(width: 1874, quality: 70)
				title
			}
			description {
				description
			}
		}
	}
`;
